import { Chart } from 'bindings/reporting/Chart';
import { CurveRepresentation } from 'bindings/reporting/CurveRepresentation';
import { TickType } from 'bindings/reporting/TickType';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import * as React from 'react';
import { Chart as ChartJSX, getElementAtEvent } from 'react-chartjs-2';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { onLinkClick } from './ReportInterpretor';
import { formatCurrency, getLang } from '../utils';
import { ReportInterpretorProviderLinksProps } from './hoverContext';

function curveRepresentationToChartJs(representation: CurveRepresentation): 'line' | 'bar' {
	switch (representation) {
		case 'BAR_CHART':
			return 'bar';
		case 'CURVE':
		default:
			return 'line';
	}
}

function formatTick(tick: TickType, value: string | number): string | number {
	const translate = getTranslate(storeLang.getState().localize);
	switch (tick) {
		case 'currency': {
			if (typeof value === 'number')
				return formatCurrency(value);
			else {
				const parsed = parseFloat(value);
				if (!isNaN(parsed))
					return formatCurrency(parsed);
				else 
					return formatCurrency(null);
			}
		}
		case 'time': 
			return moment(value).format('L - LT');
		case 'date': {
			const date = new Date(value);
			const now = new Date(Date.now());
			if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
				return translate('shelf_audit.sections.misc.today').toString();
			} else {
				return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
			}
		}
		case 'number':
		case 'string':
			return value;
		case 'percentage': {
			if (typeof value === 'number') {
				return `${value}%`;
			}
			const parsed = parseFloat(value);
			if (!isNaN(parsed))
				return `${parsed}%`;
			else 
				return '-';
		}
	} 
}

export default function ChartReportingV2(props: {
	setToolBarState: (a) => void,
	chart: Chart
} & ReportInterpretorProviderLinksProps) {
	const [refresh, setRefresh] = React.useState({});
	const chartRef = React.useRef();
	const { chart, options } = React.useMemo(() => {
		const chart = props.chart;
		const options: ChartOptions<ChartType> = {
			indexAxis: chart.mainAxis.key === 'X' ? 'x' : 'y',
			plugins: {
				datalabels: {
					display: function() {
						return false;
					},
				}
			},
		};
		options.scales = {};
		options.scales[chart.mainAxis.key === 'X' ? 'x' : 'y'] = {
			stacked: chart.stacked,
			title: {
				display: true,
				text: chart.mainAxis.title
			},
			ticks: {
				callback: function(_, index, __) {
					return formatTick(chart.mainAxis.ticksType, chart.mainAxis.labels[index]);
				} 
			}
		};
		options.scales[chart.mainAxis.key === 'X' ? 'y' : 'x'] = {
			stacked: chart.stacked,
			min: chart.secondaryAxis.min ?? undefined,
			max: chart.secondaryAxis.max ?? undefined,
			title: {
				display: true,
				text: chart.secondaryAxis.title
			},
			ticks: {
				stepSize: chart.secondaryAxis.scale ?? undefined,
				// 	stepSize: 1 / chart.y_axis.values.length,
				callback: function(value, _, __) {
					return formatTick(chart.secondaryAxis.ticksType, value);
				} 
			}
		};
		return { chart, options };
	}, [refresh, props.chart]);

	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <></>,
			bottomRightToolbarComponent: <DefaultButton onClick={() => setRefresh({})}>Refresh</DefaultButton>
		});
	}, []);

	return <ChartJSX
		style={{ maxHeight: '100%' }}
		type='bar'
		ref={React.useCallback((node) => {
			chartRef.current = node;
		}, [])}
		options={options}
		onClick={e => {
			if (!chartRef.current) return;
			const index = getElementAtEvent(chartRef.current, e)[0];
			if (index === undefined) return;
			onLinkClick(chart.curves[index.datasetIndex].link, { onCompanyClick: props.onCompanyClick, onFormInstanceClick: props.onFormInstanceClick });
		}}
		data={{
			labels: chart.mainAxis.labels,
			datasets: chart.curves.map((curve): ChartDataset => {
				return ({
					...curve,
					type: curveRepresentationToChartJs(curve.representation),
					label: curve.title,
					data: curve.curveValues,
					borderColor: curve.color ?? undefined,
					borderWidth: 2,
					backgroundColor: curve.color ?? undefined,
					// @ts-expect-error LIBRARY NOT MY FAULT FUCK IT
					lineTension: 0.33,
				});
			}),
		}}
	/>;
}