import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely, LightBlueSidely, BorderColor, SidelyBlack, FilterBlue, DarkGreySidely2, GreySidely, FilterDarkGrey2, LightGreySidely2 } from '../../styles/global/css/Utils';
import { isSuperAdmin } from '../utils';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Open } from '../../styles/global/css/Open';
import { To } from '../../Types';
import Restricted from '../../containers_v2/permissions/Restricted';
import { isOwner, useMe } from '../../atoms/global/users';
import BetaImage from 'images/icon/beta.png';
import { FlexDiv } from '../../containers_v2/products/style';
import setting from 'images/setting_icons/system_setting_icon.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragAndDropImage from 'images/icons/drag_and_drop_dots.svg';
import Popup from '../popup/Popup';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { Translate } from 'react-localize-redux';
import Input from '../input/Input';
import { DropdownContainer, DropdownContainerLeft, DropdownContainerRight, DropdownOptionBody, DropdownOptionContainer, DropdownOptionHeader } from '../filter/style/AdvancedFilterStyle';
import InputSearch from '../input/InputSearch';
import { translateToString } from '../../styles/global/translate';
import { AUsers } from '../../atoms/global/users';
import { useRecoilValue } from 'recoil';
import Avatar from '../avatar/Avatar';
import { checkClickOut } from '../dropdown/Dropdown';
import { getUserCategories, syncUserCategories, ReportGroup } from './actions';
import close from 'images/icons/orders/close.svg';
import save from 'images/reports/save.svg';
import add from 'images/icon/add.png';
import edit from 'images/icon/edit.png';
import { Owner } from '../../containers_v2/orders/model/Model';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { PopupMode } from '../popup/model/Model';
import Add from '../add/Add';
import { NewReport } from '../../containers_v2/reports/generic/generic';
import { AlertContext } from '../../containers_v2/alert/AlertProvider';

const ReportTiles = styled.div<{settingsOn: boolean}>`
	height: 53px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 24px;
	font-weight: 600;
	cursor: pointer;
	position: sticky;
	top: 0;
	background: white;
	z-index: 1;
	${p => p.settingsOn ? `
		padding: 20px 24px 20px 34px;
		&::before {
			position: absolute;
			left: 10px;
			top: 25%;
			content: '';
			background-image: url(${DragAndDropImage});
			background-size: 20px;
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
			height: 30px;
			background-size: 7px;
		}
	` : ''};
`;
  
const LocalOpen = styled.div<{transform?: string}>`
	float: right;
	top: 50%;
	transform: ${p => p.transform ?? 'translateY(-50%)'};
	position: relative;
`;

const StyledCategories = styled.div`
	display: flex;
	flex-flow: column;

	.nav-tabs {
		border: none;
	}

	.nav-link {
		color: ${SidelyBlack};
		background-color: transparent;
		box-shadow: none;
		border-top: 1px solid ${BorderColor};
		border-bottom: 1px solid ${BorderColor};
		padding: 1.25rem 1.5rem;
		position: relative;
		border-left: none;
	}
	.active-block {
		position: absolute;
		top: 0;
		left: 0;
		width: 10px;
		height: 100%;
		background-color: ${BlueSidely};
	}
`;

const NavLinkNoHover = styled(NavLink)<{settingsOn: boolean, isBeingDragged?: boolean, draggingOver: string, categoryId: string}>`
	border: none !important;
	border-top: 1px solid ${BorderColor} !important;
	border-bottom: 1px solid ${BorderColor} !important;
	position: relative;
	background-color: ${p => {
		const isGreen = p.draggingOver ? p.draggingOver.match(/\d+/)?.[0] !== p.categoryId : false;
		return p.isBeingDragged ? `${ isGreen ? '#caedd7' : LightBlueSidely }` : 'white';
	}} !important;
	border-radius: ${p => p.isBeingDragged ? '5px' : '0'} !important;
	${p => p.settingsOn ? `
		&::before {
			position: absolute;
			left: 20px;
			top: 25%;
			content: '';
			background-image: url(${DragAndDropImage});
			background-size: 20px;
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
			height: 30px;
			background-size: 6px;
		}
	` : ''};
`;

const SettingIcon = styled.img<{settingsOn: boolean}>`
	width: 20px;
	aspect-ratio: 1;
	cursor: pointer;
	${p => p.settingsOn ? `filter: ${FilterBlue}` : ''}
	&:hover {
		filter: ${FilterBlue};
	}
`;

const Title = styled.h1`
	font-size: 15px;
	font-weight: 600;
`;

const PopupWrapper = styled.div`
	height: 100%;
	padding: 20px;
	display: flex;
	flex-flow: column;
	gap: 10px;
	overflow-y: auto;
`;

const InputDescription = styled.span`
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

const InputPlaceholderSpan = styled.span`
	padding-left: 10px;
	font-size: 12px;
	font-weight: 400;
	color: ${GreySidely};
`;

const SharedWithContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 40vh;
	width: 100%;
	overflow-x: auto;
	border: 1px solid ${BorderColor};
	border-radius: 5px;
`;

const ShareWithUser = styled.div`
	padding: 10px 0;
	border-bottom: 1px solid ${BorderColor};
`;

const UserPhoto = styled.img<{size?: string}>`
	width: ${p => p.size ?? '30px'};
	aspect-ratio: 1;
	border-radius: 50%;
`;

const UserShared = styled.div<{index: number, size: number, noBorder?: boolean}>`
	position: relative;
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;
	${p => (p.index !== p.size - 1) && !p.noBorder ? ` &::after {
		content: '';
		position: absolute;
		bottom: 0;
		height: 1px;
		width: 100%;
		background-color: ${BorderColor};
	}` : ''};
`;

const UserLabel = styled.div`
	display: block;
	width: 100%;
	font-size: 13px;
	white-space: normal;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const StyledRadio = styled.input`
	width: 15px;
	aspect-ratio: 1;
	accent-color: hsl(198, 100%, 44%);
	filter: brightness(1.16);
	cursor: pointer;
`;

const DroppableReportZone = styled.div<{isSettingsOn: boolean, isReportDropOn: boolean, isDraggedOver: boolean}>`
	height: ${p => p.isReportDropOn ? '60px' : `${p.isSettingsOn ? '5px' : '0'}`};
	width: 98%;
	margin-left: 4px;
	margin-top: 1px;
	border: ${p => p.isReportDropOn ? `2px solid ${ p.isDraggedOver ? '#caedd7' : BlueSidely}` : ''};
	border-right-width: 3px;
	border-radius: 4px;
	opacity: 0.7;
`;

const BlackImgWrapper = styled.div`
	filter: ${FilterDarkGrey2};
`;

const BlueHoverWrapper = styled.div`
	&:hover {
		filter: ${FilterBlue};
	}
`;

const GreyDot = styled.div`
	width: 20px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: ${LightGreySidely2};
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: ${LightBlueSidely};
	}
`;

const BlackImg = styled.img<{size?: string}>`
	cursor: pointer;
	aspect-ratio: 1 / 1;
	width: ${p => p.size ?? '20px'};
	filter: ${FilterDarkGrey2};
	&:hover {
		filter: ${FilterBlue};
	}
`;

export type CategoryChild<T = unknown> = {
	name?: React.ReactNode,
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	admin?: boolean,
	owner?: boolean,
	active?: boolean,
	beta?: boolean,
	value?: T,
	restricted?: To | To[],
	create_report?: boolean,
	report_id?: number,
};

export type Category<T = unknown> = {
	name: React.ReactNode,
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	children: CategoryChild<T>[],
	defaultOpen?: boolean,
	hidden?: boolean,
	value?: T,
	restricted?: To | To[],
	owner?: boolean,
	admin?: boolean,
	report_group_id?: number,
	sharedWith?: number[],
	public?: boolean,
	created_by?: number,
	is_deleted?: boolean,
	sequence?: number,
}

function concatRestriction(category: Category): To[] {
	const res: To[] = [];

	const pushToRes = (restricted?: To | To[]) => {
		if (Array.isArray(restricted)) {
			res.concat(restricted);
		} else if (restricted) {
			res.push(restricted);
		}
	};
	pushToRes(category.restricted);
	category.children.forEach(child => pushToRes(child.restricted));

	return res;
}

function isOldReportCategory(category: Category): boolean {
	return (category.children.length > 0 && typeof category.children[0].name !== 'string');
}

function canDragDropReport(me: Owner, categoryOwner?: number): boolean {
	if (categoryOwner) {
		return (me.id === categoryOwner);
	}
	else {
		return (isOwner(me));
	}
}

function launchToastError(message: string) {
	toast.error(message, {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}

function resertCategoriesActive(categories: Category[]): void {
	categories.forEach(category => {
		category.children.forEach(child => {
			child.active = false;
		});
	});
}

function CategoryDraggable(props: {
	categories: Category[],
	category: Category,
	isSettingsOn: boolean,
	isAdmin: boolean,
	me: Owner,
	categoryId: number,
}): JSX.Element {
	const { categories, category, isSettingsOn, isAdmin, me, categoryId } = props;
	return <div>
		{category.children
			.filter(c => !((c.admin && !isAdmin) || (c.owner && !isOwner(me) && !isAdmin)))
			.map((child, ci) => 
				<Draggable key={`categoryChild[${categoryId}][${ci}]`} 
					draggableId={`categoryChild[${categoryId}][${ci}]`}
					isDragDisabled={!isSettingsOn || !canDragDropReport(me, category.created_by)}
					index={ci}
					type='Report'>
					{(provided, snapshot): React.ReactElement<HTMLElement> => (
						<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
							<Restricted to={child.restricted ?? []} >
								{child.name &&
								<NavItem>
									{!isSettingsOn &&
									<NavLinkNoHover onClick={(e) => {
										if (categories && categories.length > 0)
											resertCategoriesActive(categories);
										child.active = true;
										if (child.onClick)
											child.onClick(e);
									}} >
										{child.active && <div className="active-block"/>}
										{child.beta && <img src={BetaImage} style={{ width: '30px', marginTop: '-15px', marginLeft: '195px', position: 'absolute' }} />}
										<div style={{ color: child.active ? BlueSidely : undefined }}>
											{child.name}
										</div>
									</NavLinkNoHover>
									}
									{isSettingsOn && !child.create_report &&
									<NavLinkNoHover settingsOn={isSettingsOn && canDragDropReport(me, category.created_by)}
										isBeingDragged={snapshot.isDragging}
										draggingOver={snapshot.draggingOver}
										categoryId={categoryId.toString()}>
										<div style={{ paddingLeft: '20px' }}>
											{child.name}
										</div>
									</NavLinkNoHover>
									}
								</NavItem>}
							</Restricted>
						</div>
					)}
				</Draggable>
			)
		}
	</div>;
}

function CategoryDropdown(props: {
	categories: Category[],
	category: Category,
	categoryId: number,
	isSettingsOn: boolean,
	isOpen: number,
	setIsOpen: React.Dispatch<React.SetStateAction<number>>,
	isOpenSettings: boolean[],
	setIsOpenSettings: React.Dispatch<React.SetStateAction<boolean[]>>,
	isAdmin: boolean,
	me: Owner,
	isReportDropOn: boolean[],
	setPopupMode: React.Dispatch<React.SetStateAction<PopupOpenMode>>,
	setCategoryToEditId: React.Dispatch<React.SetStateAction<number>>,
	createReportAllowed?: boolean,
}): JSX.Element {
	const { categories, category, categoryId, isSettingsOn, isOpen, setIsOpen, isOpenSettings, setIsOpenSettings, isAdmin, me, isReportDropOn, setPopupMode, setCategoryToEditId, createReportAllowed } = props;
	const { alert } = React.useContext(AlertContext);
	
	return <Restricted to={concatRestriction(category)} >
		<ReportTiles
			settingsOn={isSettingsOn && category.report_group_id !== 0}
			hidden={category.hidden}
			onClick={e => {
				(e) => e.stopPropagation();
				category.onClick?.(e);
				if (!isSettingsOn)
					setIsOpen(isOpen == categoryId ? -1 : categoryId);
				else 
					setIsOpenSettings(isOpenSettings.map((e, index) => index === categoryId ? !e : e));
			}}
		>
			{category.report_group_id === 0 ? translateToString('reports.categories.shared_reports') : category.name}
			<FlexDiv gap={'6px'}>
				{isSettingsOn && category.created_by === me.id && 
					<BlackImg size='18px' src={edit} onClick={
						e => {
							e.stopPropagation();
							setCategoryToEditId(categoryId);
							setPopupMode(PopupOpenMode.EDIT);
						}
					}/>
				}
				<LocalOpen transform='translateY(-10%)'>
					<Open isOpen={!isSettingsOn ? isOpen === categoryId : isOpenSettings[categoryId]} />
				</LocalOpen>
			</FlexDiv>
		</ReportTiles>
		<Collapse isOpen={!isSettingsOn ? isOpen === categoryId : isOpenSettings[categoryId]} style={{ marginBottom: '1px' }}>
			<Droppable droppableId={`reportsOrigin[${categoryId}]`} type='Report' isDropDisabled={true}>
				{(provided): React.ReactElement<HTMLElement> => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						<CategoryDraggable
							categories={categories}
							category={category}
							isSettingsOn={isSettingsOn}
							isAdmin={isAdmin}
							me={me}
							categoryId={categoryId}
						/>
					</div>
				)}
			</Droppable>
			{ createReportAllowed && !isSettingsOn && ((category.created_by === me.id) || isOwner(me)) && 
				<NavItem>
					<NavLinkNoHover settingsOn={false} onClick={() => {
						alert({
							title: translateToString('reports.name_report'),
							content: (onBreak, onOk) => <NewReport onBreak={onBreak} onOk={onOk} categoryId={category.report_group_id}/>,
							noButtons: true,
						});
					}}>
						<FlexDiv gap='6px'><Add size='20px'/>
							<div style={{ fontSize: '12px', fontWeight: '300' }}>
								<Translate id='reports.create_report'></Translate>
							</div>
						</FlexDiv>
					</NavLinkNoHover>
				</NavItem>
			}
		</Collapse>
		<Droppable droppableId={`reports[${categoryId}]`} type='Report'>
			{(provided, snapshot): React.ReactElement<HTMLElement> => (
				<div {...provided.droppableProps} ref={provided.innerRef}>
					{canDragDropReport(me, category.created_by) && 
					<DroppableReportZone isSettingsOn={isSettingsOn} isReportDropOn={isReportDropOn[categoryId]} isDraggedOver={snapshot.isDraggingOver}/>}
				</div>)}
		</Droppable>
	</Restricted>;
}

enum PopupOpenMode {
    CLOSED = 0,
	NEW_REPORT = 1,
    EDIT = 2,
	VIEW = 3
}

function convertReportGroupsToCategories(
	reportGroup: ReportGroup[],
	reports: CategoryChild[],
	sortCategoryChildren: (a: CategoryChild, b: CategoryChild) => number
): Category[] {
	const categories: Category[] = [];

	reportGroup.forEach(rg => {
		const children: CategoryChild[] = [];
		rg.reports.forEach(r => {
			const report = reports.find(e => e.report_id === r);
			if (report) {
				children.push({
					name: report.name,
					onClick: report.onClick,
					admin: report.admin,
					owner: report.owner,
					active: report.active,
					beta: report.beta,
					value: report.value,
					report_id: report.report_id,
				});
			}
		});
		children.sort(sortCategoryChildren);
		categories.push({
			name: rg.report_group_name,
			children: children,
			defaultOpen: false,
			hidden: false,
			restricted: { objectAction: 'ReadForm' },
			report_group_id: rg.report_group_id,
			sharedWith: rg.shared_with,
			public: rg.public,
			created_by: rg.created_by,
			is_deleted: false,
			sequence: rg.sequence,
		});
	});
	return categories;
}

function convertCategoriesToReportGroups(categories: Category[], isSharedReports: boolean): ReportGroup[] {
	const reportGroups: ReportGroup[] = [];
	categories.forEach((c, i) => {
		const reports = c.children.map(r => r.report_id as number);
		reportGroups.push({
			report_group_id: c.report_group_id as number,
			report_group_name: c.name as string,
			sequence: isSharedReports ? 0 : i + 1,
			created_by: c.created_by as number,
			public: c.public as boolean,
			reports: reports,
			shared_with: c.sharedWith as number[],
			is_deleted: isSharedReports ? false : c.is_deleted as boolean,
		});
	});
	return reportGroups;
}

function CategoryCreationPopup(props: {
	popupOpenMode: PopupOpenMode,
	setPopupMode: React.Dispatch<React.SetStateAction<PopupOpenMode>>,
	categories: Category[],
	categoryId: number,
	setCategories: React.Dispatch<React.SetStateAction<Category[]>>,
	me: Owner,
	setIsOpenSettings: React.Dispatch<React.SetStateAction<boolean[]>>,
	isOpenSettings: boolean[],
}): JSX.Element {
	const { popupOpenMode, setPopupMode, categories, categoryId, setCategories, me, setIsOpenSettings, isOpenSettings } = props;
	const [newCategoryName, setNewCategoryName] = React.useState<string>('');
	const [isDropDownOpen, setIsDropDownOpen] = React.useState<boolean>(false);
	const [usersShared, setUsersShared] = React.useState<number[]>([me.id]);
	const [isPublic, setIsPublic] = React.useState<boolean>(isOwner(me) ? true : false);
	const [searchInput, setSearchInput] = React.useState<string>('');
	let users = useRecoilValue(AUsers);
	users = users.filter(u => u.id !== me.id && u.status);

	const wrapperRef = React.useRef<HTMLDivElement>(null);
	checkClickOut(wrapperRef, setIsDropDownOpen);

	function resetStateOpen() {
		setPopupMode(PopupOpenMode.CLOSED);
		setNewCategoryName('');
		setUsersShared([me.id]);
		setIsPublic(isOwner(me) ? true : false);
		setSearchInput('');
	}

	React.useEffect(() => {
		setIsDropDownOpen(false);
		let categoryName: string | React.ReactNode = '';
		if (categories && categories[categoryId] && categories[categoryId].name) {
			categoryName = categories[categoryId].name;
		}
		if (typeof categoryName === 'string'
			&& categoryName.length > 0
			&& (popupOpenMode === PopupOpenMode.EDIT || popupOpenMode === PopupOpenMode.VIEW)) {
			setNewCategoryName(categoryName);
		}
		if (popupOpenMode === PopupOpenMode.EDIT || popupOpenMode === PopupOpenMode.VIEW) {
			setIsPublic(categories[categoryId].public ?? (isOwner(me) ? true : false));
			setUsersShared(categories[categoryId].sharedWith ?? [me.id]);
		}
	}, [popupOpenMode]);

	function getTitle(): string {
		switch (popupOpenMode) {
			case PopupOpenMode.NEW_REPORT:
				return 'reports.categories.create';
			case PopupOpenMode.EDIT:
				return 'reports.categories.edit';
			default :
				return 'reports.categories.view';
		}
	}

	return <Popup isOpen={popupOpenMode !== PopupOpenMode.CLOSED}
		hasBackground
		onClickOut={() => resetStateOpen()}
		popupStyle={{
			width: '25vw',
			height: '100%',
			left: '75vw',
			top: '0',
			boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)',
			animate: true
		}}
		content={
			<PopupWrapper>
				<Title>
					<Translate id={getTitle()}/>
				</Title>
				<FlexDiv height='100%' flow='column' align='stretch' gap='20px' padding='0 10px'>
					<FlexDiv gap='4px' flow='column' align='stretch'>
						<InputDescription><Translate id="reports.categories.name"/></InputDescription>
						<Input name='name'
							type="text"
							value={newCategoryName ?? ''}
							inputStyle={{ borderRadius: '5px', width: 'calc(25vw - 80px)', height: '50px', padding: '0px 10px' }}
							onChange={e => {
								setNewCategoryName(e.trim());
							}} />
					</FlexDiv>
					{ isOwner(me) &&
					<FlexDiv gap='20px' align='stretch'>
						<InputDescription><Translate id="reports.categories.visibility"/></InputDescription>
						<FlexDiv flow='column' align='stretch' gap='5px'>
							<FlexDiv gap='5px' cursor='pointer' onClick={() => setIsPublic(true)}>
								<StyledRadio type='radio' name='public' checked={isPublic}/>
								<InputDescription><Translate id="reports.categories.public"/></InputDescription>
							</FlexDiv>
							<FlexDiv gap='5px' cursor='pointer' onClick={() => setIsPublic(false)}>
								<StyledRadio type='radio' name='restricted' checked={!isPublic}/>
								<InputDescription><Translate id="reports.categories.restricted"/></InputDescription>
							</FlexDiv>
						</FlexDiv>
					</FlexDiv>
					}
					{ !isPublic && isOwner(me) &&
					<FlexDiv gap='4px' flow='column' align='stretch'>
						<InputDescription><Translate id="reports.categories.share"/></InputDescription>
						<DropdownContainer onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ width: 'calc(25vw - 80px)' }} innerRef={wrapperRef}>
							<DropdownContainerLeft>
								<InputPlaceholderSpan><Translate id="reports.categories.share_category"/></InputPlaceholderSpan>
							</DropdownContainerLeft>
							<DropdownContainerRight style={{ paddingRight: '10px' }}>
								<Open isOpen={isDropDownOpen} />
							</DropdownContainerRight>
							{ isDropDownOpen &&
					<DropdownOptionContainer onClick={(e) => e.stopPropagation()} style={{ width: '100%' }}>
						<DropdownOptionHeader>
							<InputSearch placeholder={translateToString('global.search')}
								name={'seach_user'} 
								type='text' 
								value={searchInput}
								onChange={(value) => setSearchInput(value) }>
							</InputSearch>
						</DropdownOptionHeader>
						<DropdownOptionBody style={{ height: 'min(50vh, fit-content)' }}>
							{
								users.filter(u => u.name.toLowerCase().includes(searchInput.trim().toLowerCase()))
									.map((user, i) => <ShareWithUser key={`user[${i}]`} 
										onClick={() => {
											if (usersShared.includes(user.id)) {
												setUsersShared(usersShared.filter(e => e !== user.id));
											} else {
												setUsersShared([...usersShared, user.id]);
											}
										}}>
										<FlexDiv justify='space-between' >
											<FlexDiv gap='10px'>
												{ user.photoUrl ?
													<UserPhoto src={user.photoUrl} />
													:
													<Avatar backgroundColor={user.color} userId={user.id} name={user.name} width='30px' />
												}
												<div style={{ fontSize: '13px' }}>{user.name}</div>
											</FlexDiv>
											<FlexDiv>
												<input type='checkbox' style={{ width: '15px', height: '15px' }} checked={usersShared.includes(user.id)}/>
											</FlexDiv>
										</FlexDiv>
									</ShareWithUser>)}
						</DropdownOptionBody>
					</DropdownOptionContainer>
							}
						</DropdownContainer>
					</FlexDiv>
					}
					<FlexDiv gap='4px' flow='column' align='stretch'>
						<InputDescription><Translate id="reports.categories.shared_with"/></InputDescription>
						<SharedWithContainer>
							{ isPublic ?
								<UserShared index={-1} size={usersShared.length} noBorder>
									<UserLabel><Translate id='all'/></UserLabel>
								</UserShared>
								:
								<>
									<UserShared index={-1} size={usersShared.length}>
										{ me.photoUrl ?
											<UserPhoto src={me.photoUrl} size='25px' />
											:
											<Avatar backgroundColor={me.color} userId={me.id} name={me.name} width='25px' />
										}
										<UserLabel><Translate id='me'/></UserLabel>
									</UserShared>
									{ usersShared.map((userId, i) => {
										if (userId === me.id) return null;
										const user = users.find(e => e.id === userId);
										return <UserShared key={`sharedUser[${i}]`} index={i} size={usersShared.length}>
											{ user?.photoUrl ?
												<UserPhoto src={user.photoUrl} size='25px' />
												:
												<Avatar backgroundColor={user?.color} userId={user?.id} name={user?.name} width='25px' />
											}
											<UserLabel>{user?.name}</UserLabel>
										</UserShared>;
									})}
								</>
							}
						</SharedWithContainer>
					</FlexDiv>
					<FlexDiv gap='4px' flow='column' align='stretch'>
						<FlexDiv justify='center'>
							<DefaultButton buttonStyle={2} height='38px' width='calc(25vw - 210px)' onClick={() => {
								resetStateOpen();
							}}><Translate id='cancel'/></DefaultButton>
							<DefaultButton
								height='38px'
								width='calc(25vw - 210px)'
								disabled={!newCategoryName.length}
								onClick={() => {
									if (popupOpenMode === PopupOpenMode.EDIT) {
										const newCategories = [...categories];
										newCategories[categoryId].name = newCategoryName;
										newCategories[categoryId].public = isPublic;
										newCategories[categoryId].sharedWith = usersShared;
										setCategories(newCategories);
									} else if (popupOpenMode === PopupOpenMode.NEW_REPORT) {
										setCategories([...categories, {
											name: newCategoryName,
											children: [],
											owner: true,
											admin: true,
											public: isPublic,
											created_by: me.id,
											sharedWith: usersShared,
											is_deleted: false,
										}]);
										const newIsOpenSettings = [...isOpenSettings];
										newIsOpenSettings.splice((newIsOpenSettings.length - 2), 0, false);
										setIsOpenSettings(newIsOpenSettings);
									}
									resetStateOpen();
								}}><Translate id='save'/></DefaultButton>
						</FlexDiv>
						{popupOpenMode === PopupOpenMode.EDIT && 
							<DefaultButton buttonStyle={3} height='38px' width='calc(25vw - 100px)'
								onClick={() => {
									if (categories[categoryId].children.length === 0) {
										resetStateOpen();
										if (categories[categoryId].report_group_id !== undefined) {
											categories[categoryId].is_deleted = true;
										} else {
											const newCategories = [...categories];
											newCategories.splice(categoryId, 1);
											setCategories(newCategories);
										}
										setIsOpenSettings(isOpenSettings.filter((_, index) => index !== categoryId));
									}
									else
										launchToastError(translateToString('reports.categories.error_delete'));
								}}>
								<Translate id='delete'/>
							</DefaultButton>}
					</FlexDiv>
				</FlexDiv>
			</PopupWrapper>
		}
	/>;
}

export default function CategorySelector(props: {
	categories: Category[],
	title?: React.ReactNode,
	hasSettings?: boolean
}): JSX.Element {
	const me = useMe();
	const isAdmin = isSuperAdmin();
	const [categories, setCategories] = React.useState<Category[]>([]);
	const [defaultCategories, setdefaultCategories] = React.useState<Category[]>([]);
	const [reports, setReports] = React.useState<CategoryChild[]>([]);
	const [isSettingsOn, setIsSettingsOn] = React.useState<boolean>(false);
	const [userCategories, setUserCategories] = React.useState<ReportGroup[]>([]);
	const [isReportDropOn, setIsReportDropOn] = React.useState<boolean[]>([]);
	const [popupMode, setPopupMode] = React.useState<PopupOpenMode>(PopupOpenMode.CLOSED);
	const [categoryToEditId, setCategoryToEditId] = React.useState<number>(0);
	const [originalCategories, setOriginalCategories] = React.useState<Category[]>([]);
	const [originalDefaultCategories, setOriginalDefaultCategories] = React.useState<Category[]>([]);
	const allCategories = [...categories, ...defaultCategories];
	const [confirmClose, setConfirmClose] = React.useState<boolean>(false);

	function checkOpenSettings(): boolean[] {
		return (allCategories.map(
			c => {
				if (c.children.length === 0) return false;
				return c.children.some(e => e.active);
			}
		));
	}

	function checkOpen(): number {
		return allCategories.findIndex(
			c => {
				if (c.children.length === 0) return false;
				return c.children.some(e => e.active);
			}
		);
	}

	const [isOpen, setIsOpen] = React.useState<number>(checkOpen());
	const [isOpenSettings, setIsOpenSettings] = React.useState<boolean[]>(checkOpenSettings());
	function resetIsReportDropOn() {
		setIsReportDropOn(Array(categories.length + 1).fill(false));
	}

	function sortCategoryChildren(a: CategoryChild, b: CategoryChild): number {
		if (a.name && typeof a.name === 'string' && b.name && typeof b.name === 'string')
			return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
		else
			return 0;
	}

	function onDragEnd({ type, destination, source }) {
		if (type === 'Report') {
			if (!destination || !source || source.droppableId === destination.droppableId) {
				resetIsReportDropOn();
				return;
			}
			let sourceDropIndex = source.droppableId.match(/\d+/)?.[0];
			let destinationDropIndex = destination.droppableId.match(/\d+/)?.[0];
			if (sourceDropIndex && destinationDropIndex) {
				sourceDropIndex = parseInt(sourceDropIndex);
				const newCategories = _.cloneDeep(categories);
				const newDefaultCategories = _.cloneDeep(defaultCategories);
				let removed: CategoryChild;
				if (sourceDropIndex === categories.length) { //dragging a report from the default category
					[removed] = newDefaultCategories[0].children.splice(source.index, 1);
				}
				else
					[removed] = newCategories[sourceDropIndex].children.splice(source.index, 1);
				destinationDropIndex = parseInt(destinationDropIndex);
				if (destinationDropIndex === categories.length) { //dropping a report in the default category
					newDefaultCategories[0].children.splice(0, 0, removed);
					if (newDefaultCategories[0].children.length > 1) {
						newDefaultCategories[0].children = 
						newDefaultCategories[0].children.sort(sortCategoryChildren);
					}
				}
				else {
					newCategories[destinationDropIndex].children.splice(0, 0, removed);
					if (newCategories[destinationDropIndex].children.length > 1) {
						newCategories[destinationDropIndex].children = 
						newCategories[destinationDropIndex].children.sort(sortCategoryChildren);
					}
				}
				setCategories(newCategories);
				setdefaultCategories(newDefaultCategories);
			}
		}
		else if (type === 'Category') {
			if (!destination || !source || source.index === destination.index) {
				resetIsReportDropOn();
				return;
			}
			const newCategories = [...categories];
			const [removed] = newCategories.splice(source.index, 1);
			newCategories.splice(destination.index, 0, removed);
			const newIsOpenSettings = [...isOpenSettings];
			const [removedIsOpen] = newIsOpenSettings.splice(source.index, 1);
			newIsOpenSettings.splice(destination.index, 0, removedIsOpen);
			setCategories(newCategories);
			setIsOpenSettings(newIsOpenSettings);
		}
		resetIsReportDropOn();
	}

	function onDragStart(event: any) {
		if (event.type === 'Report') {
			const allNewCategories = [...categories, ...defaultCategories];
			setIsReportDropOn(allNewCategories.map((_, i) => i === parseInt(event.source.droppableId.match(/\d+/)?.[0] ?? '') ? false : true));
		}
	}

	React.useEffect(() => {
		if (props.hasSettings) {
			getUserCategories(me.id).then(data => {
				setUserCategories(data);
			});
		}
	}, []);

	React.useEffect(() => {
		const categoriesFiltered = props.categories?.filter(c => !c.hidden && !((c.admin && !isAdmin) || (c.owner && !isOwner(me) && !isAdmin))) ?? [];
		setIsOpen(checkOpen());
		setIsOpenSettings(checkOpenSettings());
		if (!props.hasSettings)
			setdefaultCategories(categoriesFiltered.filter(c => !isOldReportCategory(c)));
		setReports(categoriesFiltered.map(c => c.children).flat());
	}, [props.categories]);

	React.useEffect(() => {
		if (userCategories.length) {
			const allCategories = convertReportGroupsToCategories(userCategories, reports, sortCategoryChildren);
			if (allCategories.length > 0) {
				const defaultCategories = allCategories.filter(c => c.report_group_id === 0);
				const otherCategories = allCategories.filter(c => c.report_group_id !== 0);
				setOriginalDefaultCategories(defaultCategories);
				setdefaultCategories([...defaultCategories]);
				setOriginalCategories(otherCategories);
				setCategories([...otherCategories]);
			}
		}
	}, [reports, userCategories]);

	React.useEffect(() => {
		setIsOpen(checkOpen());
		resetIsReportDropOn();
	}, [categories]);

	React.useEffect(() => {
		setIsOpenSettings(checkOpenSettings());
	}, [originalCategories, originalDefaultCategories]);

	return <>
		<CategoryCreationPopup
			popupOpenMode={popupMode}
			setPopupMode={setPopupMode}
			categories={categories}
			categoryId={categoryToEditId}
			setCategories={setCategories}
			me={me}
			setIsOpenSettings={setIsOpenSettings}
			isOpenSettings={isOpenSettings}
		/>
		<Popup isOpen={confirmClose} popupMode={PopupMode.Centered}
			popupStyle={{ width: '35vw', height: '35vh' }}
			content={
				<FlexDiv padding='15px' height='100%' flow='column' justify='center' gap='5vh'>
					<span style={{ fontSize: '15px', textAlign: 'center' }}>
						<Translate id='reports.categories.confirm_close'/><br/>
						<Translate id='reports.categories.confirm_close2'/>
					</span>
					<FlexDiv justify='center' flexWrap='wrap'>
						<DefaultButton width='150px' onClick={() => {
							setConfirmClose(false);
							setIsSettingsOn(false);
							setCategories([...originalCategories]);
							setdefaultCategories([...originalDefaultCategories]);
						}}>
							<Translate id="confirm"/>
						</DefaultButton>
						<DefaultButton width='150px' buttonStyle={2} onClick={() => {
							setConfirmClose(false);
						}}>
							<Translate id="cancel"/>
						</DefaultButton>
					</FlexDiv>
				</FlexDiv>
			}
		/>
		<StyledCategories>
			<DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart} onAfterDragEnd>
				{props.title &&
				<FlexDiv justify='space-around' padding='1rem' >
					<h5 style={{ color: SidelyBlack, position: 'sticky', backgroundColor: 'inherit', width: '100%', marginBottom: '-10px' }}>
						{props.title}
					</h5>
					{props.hasSettings &&
						<FlexDiv padding='10px 0 0 0' gap='10px'>
							{isSettingsOn && <GreyDot><BlackImg src={add} size='18px' onClick={() => setPopupMode(PopupOpenMode.NEW_REPORT)}/></GreyDot>}
							{isSettingsOn && <BlackImg src={save} 
								onClick={() => {
									setIsSettingsOn(false);
									const uploadCategories = convertCategoriesToReportGroups(categories, false);
									const uploadSharedCategories = convertCategoriesToReportGroups(defaultCategories, true);
									const finalCategories = [...uploadCategories, ...uploadSharedCategories];
									syncUserCategories(me.id, finalCategories).then(() => {
										getUserCategories(me.id).then(data => {
											setUserCategories(data);
										});
									});
								}}
							/>}
							{isSettingsOn ?
								<BlueHoverWrapper><BlackImgWrapper><BlackImg src={close} size='22px' 
									onClick={() => {
										setConfirmClose(true);
									}}/></BlackImgWrapper>
								</BlueHoverWrapper>
								: 
								<SettingIcon src={setting} settingsOn={isSettingsOn} onClick={() => setIsSettingsOn(true)}/>
							}
						</FlexDiv>
					}
				</FlexDiv>}
				<Droppable droppableId='categories' type='Category'>
					{(provided): React.ReactElement<HTMLElement> => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							<Nav tabs vertical className="nav-pin" style={{ flexFlow: 'column', height: '100%' }}>
								{categories
									.map((category, i) =>
										<Draggable key={`category[${i}]`} draggableId={`category[${i}]`} index={i} isDragDisabled={!isSettingsOn} type='Category'>
											{(provided): React.ReactElement<HTMLElement> => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													{ !category.is_deleted &&
														<CategoryDropdown
															categories={allCategories}
															category={category}
															categoryId={i}
															isSettingsOn={isSettingsOn}
															isOpen={isOpen}
															setIsOpen={setIsOpen}
															isOpenSettings={isOpenSettings}
															setIsOpenSettings={setIsOpenSettings}
															isAdmin={isAdmin}
															me={me}
															isReportDropOn={isReportDropOn}
															setPopupMode={setPopupMode}
															setCategoryToEditId={setCategoryToEditId}
															createReportAllowed={true}
														/>
													}
												</div>
											)}
										</Draggable>
									)}
								{provided.placeholder}
								{
									defaultCategories.map((category, i) =>
										<CategoryDropdown key={`sharedCategory[${i}]`}
											categories={allCategories}
											category={category}
											categoryId={ categories.length + i }
											isSettingsOn={isSettingsOn}
											isOpen={isOpen}
											setIsOpen={setIsOpen}
											isOpenSettings={isOpenSettings}
											setIsOpenSettings={setIsOpenSettings}
											isAdmin={isAdmin}
											me={me}
											isReportDropOn={isReportDropOn}
											setPopupMode={setPopupMode}
											setCategoryToEditId={setCategoryToEditId}
											createReportAllowed={i === 0 && category.name === 'shared_reports'}
										/>
									)
								}
							</Nav>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</StyledCategories>
	</>;
}